<template>
    <v-navigation-drawer
        v-model="active"
        disable-resize-watcher
        right
        temporary
        fixed
        width="500"
        style="z-index: 12"
    >
        <v-container class="px-12 pb-12">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Nuevo reclamo</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <validation-observer ref="form">
                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <validation-provider name="email" v-slot="{ errors }" rules="required">
                                        <customer-select
                                            v-model="customer"
                                        ></customer-select>
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12">
                                    <v-select
                                        :disabled="!customer"
                                        v-model="order"
                                        label="Pedido"
                                        :items="orders"
                                    >
                                        <template v-slot:selection="item">
                                            {{ item.item.id }}
                                        </template>

                                        <template v-slot:item="item">
                                            <v-list-item-content>
                                                <v-list-item-title>Pedido #{{ item.item.id }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.item.deliveryDate | moment('dddd D [de] MMMM YYYY') }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <tui-order-state-chip :state="item.item.state"></tui-order-state-chip>
                                            </v-list-item-action>
                                        </template>
                                    </v-select>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                        label="Titulo"
                                        v-model="title"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Descripción del reclamo"
                                        v-model="comment"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </validation-observer>

                <v-spacer></v-spacer>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" dark block @click="onSaveSelected" :loading="isSaving">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import CustomerSelect from '@/components/Customer/CustomerSelect';
    import TuiOrderStateChip from '@/ui/TUIOrderStateChip';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            CustomerSelect,
            TuiOrderStateChip
        },

        props: {
            value: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                active: false,

                valid: false,
                showSuccessMessage: true,

                customer: null,
                order: null,
                title: '',
                comment: ''
            };
        },

        computed: {
            isSaving() {
                return this.$store.getters['claims/isSaving'];
            },

            orders() {
                if(this.customer) {
                    return this.customer.orders.map(id => this.$store.getters['order/getById'](id));
                }
                return [];
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            customer(val) {
                // fetch orders for the selected customer
                this.$store.dispatch('customer/fetchOrders', {
                    customer: this.customer,
                    offset: 0,
                    limit: 5
                });
            }
        },

        methods: {
            async onSaveSelected() {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                try {
                    let result = await this.$store.dispatch('claims/create', {
                        customer: this.customer.id,
                        order: this.order.id,
                        title: this.title,
                        comment: this.comment
                    });

                    this.$store.dispatch('app/setStatusMessage', 'Reclamo creado');

                    this.active = false;

                    this.$router.push({ name: 'claimView', params: { id: result.id } });
                }
                catch (err) {
                    let formErrors = {};

                    if(err.code === 'error.user.password_invalid') {
                        formErrors.password = this.$t(err.code);
                    }
                    else {
                        for(let errField in err.errors) {
                            formErrors[errField] = this.$t(err.errors[errField]);
                        }
                    }

                    this.$refs.form.setErrors(formErrors);
                }
            },

            onBackSelected() {
                this.active = false;
            }
        }
    };
</script>
