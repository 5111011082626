<template>
    <v-autocomplete
        v-model="customer"
        :items="customerSearchResults"
        :loading="isLoading"
        :search-input.sync="customerSearch"
        :readonly="readonly"
        no-filter
        hide-no-data
        item-text="fullName"
        item-value="id"
        label="Cliente"
        placeholder=""
        return-object
    >
        <template v-slot:item="item">
            <v-list-item-avatar
                color="indigo"
                class="headline font-weight-light white--text"
            >
                {{ item.item.fullName.charAt(0) }}
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title v-text="item.item.fullName"></v-list-item-title>
                <v-list-item-subtitle v-text="item.item.id"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
<!--                <v-icon>mdi-coin</v-icon>-->
            </v-list-item-action>
        </template>
    </v-autocomplete>
</template>

<script>
    export default {
        components: {},

        props: [ 'value', 'readonly' ],

        data() {
            return {
                customer: null,

                isLoading: false,
                customerSearch: '',
                customerSearchResults: []
            };
        },

        mounted() {

        },

        timers: {
            searchTimer: {
                time: 700,
                autostart: false
            }
        },

        watch: {
            customer(v) {
                this.$emit('input', v);
            },

            value(v) {
                if(v && this.customerSearchResults.length === 0) {
                    this.customerSearchResults.push(v);
                }

                this.customer = v;
            },

            customerSearch(value) {
                if(!value) {
                    return;
                }

                this.$timer.stop('searchTimer');
                this.$timer.start('searchTimer');
            }
        },

        methods: {
            searchTimer() {
                this.$timer.stop('searchTimer');

                this.isLoading = true;

                this.$store.dispatch('customer/fetchAll', {
                    search: this.customerSearch
                }).then((result) => {
                    this.customerSearchResults = result.ids.map(id => this.$store.getters['customer/getById'](id));
                    this.isLoading = false;
                });
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
