var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('template-layout',[_c('template',{slot:"drawers"},[_c('claim-create-drawer',{model:{value:(_vm.showCreateDrawer),callback:function ($$v) {_vm.showCreateDrawer=$$v},expression:"showCreateDrawer"}})],1),_c('template',{slot:"content"},[_c('v-container',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":"","dense":"","color":"transparent","height":"60"}},[_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Tickets")])]),_c('v-spacer'),_c('v-toolbar-title',[_c('v-text-field',{staticClass:"mr-12",staticStyle:{"max-width":"180px"},attrs:{"label":"Buscar...","color":"primary","dense":"","hide-details":"","flat":"","prepend-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-toolbar-title',[_c('v-btn',{staticClass:"elevation-0 mx-4",attrs:{"fab":"","x-small":"","color":"primary","disabled":!_vm.canCreate},on:{"click":function($event){_vm.showCreateDrawer = true;}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-header":"","item-key":"id","headers":_vm.headers,"items":_vm.claims,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.totalClaims,"no-data-text":"No hay tickets para mostrar","mobile-breakpoint":0},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" Ticket #"+_vm._s(item.id)+" - "+_vm._s(item.title)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'dddd D [de] MMMM YYYY'))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer)?[_c('v-icon',[_vm._v("mdi-account-outline")]),_vm._v(" "+_vm._s(_vm._f("capitalize")(item.customer.fullName))+" ")]:_vm._e()]}},{key:"item.assignment",fn:function(ref){
var item = ref.item;
return [(item.assignment)?[_c('v-icon',[_vm._v("mdi-face-agent")]),_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.getEmployee(item.assignment.id).fullName))+" ")]:_vm._e()]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":_vm.colorByState[item.state],"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t(item.state))+" ")])]}}])})],1)],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }