<template>
    <template-layout>
        <template slot="drawers">
            <claim-create-drawer
                v-model="showCreateDrawer"
            ></claim-create-drawer>
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Tickets</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-text-field
                            class="mr-12"
                            label="Buscar..."
                            color="primary" dense hide-details flat
                            prepend-icon="mdi-magnify"
                            v-model="search"
                            clearable
                            style="max-width: 180px;"
                        />
                    </v-toolbar-title>

                    <v-toolbar-title>
                        <v-btn fab x-small color="primary" class="elevation-0 mx-4" @click="showCreateDrawer = true;" :disabled="!canCreate"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <v-data-table
                                    hide-default-header
                                    item-key="id"
                                    :headers="headers"
                                    :items="claims"
                                    :loading="isLoading"
                                    :options.sync="options"
                                    :server-items-length="totalClaims"
                                    no-data-text="No hay tickets para mostrar"
                                    @click:row="onRowSelected"
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:item.id="{ item }">
                                        Ticket #{{ item.id }} - {{ item.title }}
                                    </template>

                                    <template v-slot:item.created_at="{ item }">
                                        {{ item.created_at | moment('dddd D [de] MMMM YYYY') }}
                                    </template>

                                    <template v-slot:item.customer="{ item }">
                                        <template v-if="item.customer">
                                            <v-icon>mdi-account-outline</v-icon>
                                            {{ item.customer.fullName | capitalize }}
                                        </template>
                                    </template>

                                    <template v-slot:item.assignment="{ item }">
                                        <template v-if="item.assignment">
                                            <v-icon>mdi-face-agent</v-icon>
                                            {{ getEmployee(item.assignment.id).fullName | capitalize }}
                                        </template>
                                    </template>

                                    <template v-slot:item.state="{ item }">
                                        <v-chip
                                            label
                                            :color="colorByState[item.state]"
                                            text-color="white"
                                        >
                                            {{ $t(item.state) }}
                                        </v-chip>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import qs from 'qs';

    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import ClaimCreateDrawer from './ClaimCreateDrawer';

    export default {
        components: {
            TemplateLayout,
            ClaimCreateDrawer
        },

        data() {
            return {
                showCreateDrawer: false,

                colorByState: {
                    open: 'green',
                    resolved: 'red'
                },

                search: '',

                defaultFilters: {
                    state: 'open'
                },

                filters: {},

                options: {},

                headers: [
                    { text: 'Id', value: 'id' },
                    { text: 'Fecha', value: 'created_at' },
                    { text: 'Cliente', value: 'customer' },
                    { text: 'Asignación', value: 'assignment' },
                    { text: 'Estado', value: 'state', align: 'right' }
                ]
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['claims/isLoading'];
            },

            canCreate() {
                return true;
            },

            claims() {
                return this.$store.getters['claims/getAll']();
            },

            totalClaims() {
                return this.$store.getters['claims/getServerTotal']();
            },

            filtered() {
                return false;
            }
        },

        watch: {
            options: {
                handler() {
                    this.updateRouteQuery();

                    this.fetchClaims();
                },
                deep: true,
            },

            filters: {
                handler(val) {
                    this.updateRouteQuery();
                },
                deep: true
            }
        },

        mounted() {
            this.$store.dispatch('employees/fetchAll');

            // start with our default filters
            this.filters = { ...this.defaultFilters };

            this.updateOptionsFromQuery();
        },

        methods: {
            getEmployee(id) {
                return this.$store.getters['employees/getById'](id) || {};
            },

            onRowSelected(item) {
                this.$router.push({ name: 'claimView', params: { id: item.id } });
            },

            updateOptionsFromQuery() {
                let query = Object.keys(this.$route.query).map(e => e + '=' + this.$route.query[e]).join('&');
                let options = { ...this.filters, ...qs.parse(query) };

                if (options.page) {
                    this.options.page = options.page;
                }

                if (options.itemsPerPage) {
                    this.options.itemsPerPage = options.itemsPerPage;
                }
            },

            updateRouteQuery() {
                let q = {};

                // if(this.search) {
                //     q.search = this.search;
                // }

                if (this.options.page !== 1) {
                    q.page = this.options.page;
                }
                
                if (this.options.itemsPerPage !== 10) {
                    q.itemsPerPage = this.options.itemsPerPage;
                }

                // if(this.pagination.sortBy !== 'name' || this.pagination.descending !== false) {
                //     q.sortBy = this.pagination.sortBy;
                //     q.sortDir = this.pagination.descending ? 'desc' : 'asc';
                // }

                // if(this.filters) {
                //     let keys = Object.keys(this.filters);
                //     for(const k of keys) {
                //         if(this.filters[k] !== this.defaultFilters[k]) {
                //             q[k] = this.filters[k];
                //         }
                //     }
                // }

                this.$router.replace({ name: 'claimList', query: q }).catch(() => {});
            },

            async fetchClaims() {
                this.isLoading = true;

                const { sortBy, sortDesc, page, itemsPerPage } = this.options;

                await this.$store.dispatch('claims/fetch', {
                    ...this.filters,
                    offset: itemsPerPage * (page - 1),
                    limit: itemsPerPage
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
